import React, { useEffect, useState } from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

const SuccessPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState<boolean | null>(null);
  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    const verifySession = async () => {
      if (!sessionId) {
        console.error('No session ID found in URL.');
        setSuccess(false);
        setLoading(false);
        return;
      }

      try {
        // Make a POST request to your server to verify the session
        const response = await fetch('https://stripe-firebase-server-807107572139.us-east1.run.app/verify-checkout-session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            sessionId: sessionId,
          }),
        });

        const result = await response.json();
        if (response.ok && result.success) {
          setSuccess(true);
        } else {
          setSuccess(false);
        }
      } catch (error) {
        console.error('Error verifying session:', error);
        setSuccess(false);
      } finally {
        setLoading(false);
      }
    };

    verifySession();
  }, [sessionId]);

  return (
    <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: '#f5f5f5',
    }}
  >
      {loading ? (
        <CircularProgress />
      ) : success ? (
        <>
          <Typography variant="h4" sx={{ color: '#5D1049', marginBottom: '16px' }}>
            Subscription Successful!
          </Typography>
          <Typography variant="body1" sx={{ color: '#412039' }}>
            Thank you for subscribing. You now have full access to all content.
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h4" sx={{ color: '#5D1049', marginBottom: '16px' }}>
            Subscription Failed
          </Typography>
          <Typography variant="body1" sx={{ color: '#412039' }}>
            Something went wrong. Please contact support.
          </Typography>
        </>
      )}
    </div>
  );
};

export default SuccessPage;
