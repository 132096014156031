import React, { useState, useEffect } from 'react';
import { Card, CardMedia, CardContent, Typography } from '@mui/material';
import styles from './BlocCard.module.css';
import { Bloc } from './models/Index';

interface BlocCardProps {
  bloc: Bloc;
  index: number;
  onClick: (bloc: Bloc, index: number) => void;
  className?: string;
}

const BlocCard: React.FC<BlocCardProps> = ({ bloc, index, onClick, className }) => {
  const [aspectRatio, setAspectRatio] = useState<'16/9' | '9/16'>('16/9');

  useEffect(() => {
    if (bloc.thumbnailUrl) {
      const img = new Image();
      img.src = bloc.thumbnailUrl;
      img.onload = () => {
        const { width, height } = img;
        setAspectRatio(width > height ? '16/9' : '9/16');
      };
    }
  }, [bloc.thumbnailUrl]);

  if (!bloc) return null;

  return (
    <div className={styles.cardWrapper}>
      <Card
        sx={{
          height: '100%',
          aspectRatio: aspectRatio, // Dynamically set aspect ratio
          backgroundColor: '#FFE0B2', // Friendly peach color
          position: 'relative', // For overlay and reflection
          boxShadow: 'none', // Maintain transparency
          cursor: 'pointer', // Add interactivity
          overflow: 'hidden', // Prevent overflow
        }}
        onClick={() => onClick(bloc, index)}
        className={`${styles.card} ${className}`}
      >
        {bloc.thumbnailUrl ? (
          <CardMedia
            component="img"
            image={bloc.thumbnailUrl}
            alt="Bloc Thumbnail"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover', // Cover the card area
              opacity: 0, // Start invisible
              transition: 'opacity 0.5s ease-in-out', // Smooth fade-in
            }}
            onLoad={(event) => {
              (event.target as HTMLImageElement).style.opacity = '1'; // Fade in when loaded
            }}
          />
        ) : (
          <div className={styles.noImage}>
            <Typography className={styles.noImageText}>No Image Available</Typography>
          </div>
        )}

        {/* Content Overlay */}
        <CardContent
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)', // Transparent black
            color: '#FFFFFF', // White text
            textAlign: 'center',
            padding: '10px 0',
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {bloc.title}
          </Typography>
        </CardContent>
      </Card>

      {/* Mirror Reflection */}
      <div className={styles.mirrorReflection}></div>
    </div>
  );
};

export default BlocCard;
