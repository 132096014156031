// import React, { useState } from 'react';
// import { loadStripe } from '@stripe/stripe-js';
// import { Button, CircularProgress, Typography } from '@mui/material';
// import { getAuth } from 'firebase/auth';
// import axios from 'axios';

// const stripePromise = loadStripe('pk_live_Vf5cEc0MGcfQGeHkVkk6Yh2T');

// const SubscribePage: React.FC = () => {
//   const [loading, setLoading] = useState(false);
//   const [loadingPremium, setLoadingPremium] = useState(false);

//   const apiBaseUrl =
//     window.location.hostname === 'localhost'
//       ? 'https://stripe-firebase-server-807107572139.us-east1.run.app'
//       : 'https://stripe-firebase-server-807107572139.us-east1.run.app';

//   const handleSubscribe = async (priceId: string, setLoadingState: React.Dispatch<React.SetStateAction<boolean>>) => {
//     setLoadingState(true);
//     try {
//       const auth = getAuth();
//       const token = await auth.currentUser?.getIdToken();
//       const userId = auth.currentUser?.uid;
//       const email = auth.currentUser?.email;

//       if (!token || !userId || !email) throw new Error('Authentication error');

//       const response = await axios.post(
//         `${apiBaseUrl}/api/checkout`,
//         { priceId, userId, email },
//         { headers: { Authorization: `Bearer ${token}` } }
//       );

//       const stripe = await stripePromise;
//       await stripe?.redirectToCheckout({ sessionId: response.data.sessionId });
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setLoadingState(false);
//     }
//   };

//   return (
//     <div
//       style={{
//         fontFamily: "'Poppins', sans-serif",
//         backgroundColor: '#fdfdfd',
//         color: '#333',
//         overflow: 'hidden',
//       }}
//     >
//       {/* Hero Section */}
//       <div
//         style={{
//           position: 'relative',
//           textAlign: 'center',
//           padding: '100px 20px',
//           background: 'url(cover-image.jpg) no-repeat center center / cover',
//           color: 'white',
//         }}
//       >
//         {/* Gradient Overlay */}
//         <div
//           style={{
//             position: 'absolute',
//             top: 0,
//             left: 0,
//             width: '100%',
//             height: '100%',
//             background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9))',
//             zIndex: 1,
//           }}
//         />
//         <div style={{ position: 'relative', zIndex: 2 }}>
//           <Typography variant="h3" style={{ fontWeight: 700, marginBottom: '20px' }}>
//             Join the Journey. Transform Your Tango.
//           </Typography>
//           <Typography variant="h6" style={{ fontWeight: 400, marginBottom: '40px' }}>
//             Unlock live classes, detailed breakdowns, and tools to master your tango.
//           </Typography>
//           <div>
//             <Button
//               style={{
//                 margin: '10px',
//                 backgroundColor: '#00796b',
//                 color: 'white',
//                 padding: '12px 24px',
//                 fontSize: '16px',
//                 fontWeight: 'bold',
//                 borderRadius: '30px',
//                 textTransform: 'none',
//               }}
              
//               //onClick={() => handleSubscribe('pro-monthly', setLoading)} //test key
//                onClick={() => handleSubscribe('TANGOVLOG_MONTHLY', setLoading)}  // LIVE
//             >
//               {loading ? <CircularProgress size={24} /> : 'Choose Basic ($10/month)'}
//             </Button>
//             <Button
//               style={{
//                 margin: '10px',
//                 backgroundColor: '#e65100',
//                 color: 'white',
//                 padding: '12px 24px',
//                 fontSize: '16px',
//                 fontWeight: 'bold',
//                 borderRadius: '30px',
//                 textTransform: 'none',
//               }}
//               //price_1QTuXQIRn82SLOephC5TSR47
//               //onClick={() => handleSubscribe('price_1QTuXQIRn82SLOephC5TSR47', setLoadingPremium)}  // TEST
//                onClick={() => handleSubscribe('price_1QTWDHIRn82SLOepJBThQ9rX', setLoadingPremium)} // LIVE
//             >                              
//               {loadingPremium ? <CircularProgress size={24} /> : 'Choose Premium ($90/month)'}
//             </Button>
//           </div>
//         </div>
//       </div>

//       {/* Subscription Cards */}
//       <div style={{ display: 'flex', justifyContent: 'center', padding: '40px 20px' }}>
//         <div
//           style={{
//             width: '350px',
//             margin: '20px',
//             padding: '20px',
//             borderRadius: '8px',
//             boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
//             backgroundColor: '#fff',
//             textAlign: 'center',
//           }}
//         >
//           <Typography variant="h5" style={{ marginBottom: '16px', color: '#00796b', fontWeight: 700 }}>
//             Basic Plan
//           </Typography>
//           <Typography style={{ marginBottom: '24px', fontSize: '18px', fontWeight: 400 }}>$10/month</Typography>
//           <ul>
//             <li>Access all video breakdowns</li>
//             <li>Speed controls</li>
//             <li>Feedback and position descriptions</li>
//             <li>Loop fine-tuned controls by beat</li>
//           </ul>
//         </div>
//         <div
//           style={{
//             width: '350px',
//             margin: '20px',
//             padding: '20px',
//             borderRadius: '8px',
//             boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
//             backgroundColor: '#fff',
//             textAlign: 'center',
//           }}
//         >
//           <Typography variant="h5" style={{ marginBottom: '16px', color: '#e65100', fontWeight: 700 }}>
//             Premium Plan
//           </Typography>
//           <Typography style={{ marginBottom: '24px', fontSize: '18px', fontWeight: 400 }}>$90/month</Typography>
//           <ul>
//             <li>All Basic Plan features</li>
//             <li>Live Zoom classes</li>
//             <li>Recordings of past classes</li>
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SubscribePage;


import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Button, CircularProgress, Typography } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import axios from 'axios';

// Use Stripe test public key
const stripePromise = loadStripe('pk_live_Vf5cEc0MGcfQGeHkVkk6Yh2T'); // Replace with your test public key

const SubscribePage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [loadingPremium, setLoadingPremium] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState<string | null>(null);
  const [subscriptionType, setSubscriptionType] = useState<string | null>(null);


    const apiBaseUrl =
    window.location.hostname === 'localhost'
      ? 'https://stripe-firebase-server-807107572139.us-east1.run.app'
      : 'https://stripe-firebase-server-807107572139.us-east1.run.app';

  const db = getFirestore();

  useEffect(() => {
    const fetchSubscriptionInfo = async () => {
      try {
        const auth = getAuth();
        const userId = auth.currentUser?.uid;

        if (!userId) throw new Error('Authentication error');

        // Fetch user document from Firestore
        const userDocRef = doc(db, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setSubscriptionStatus(userData.subscriptionStatus || 'inactive');
          setSubscriptionType(userData.subscriptionType || null);
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching subscription info:', error);
      }
    };

    fetchSubscriptionInfo();
  }, [db]);

  const handleSubscribe = async (priceId: string, setLoadingState: React.Dispatch<React.SetStateAction<boolean>>) => {
    setLoadingState(true);
    try {
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken();
      const userId = auth.currentUser?.uid;
      const email = auth.currentUser?.email;

      if (!token || !userId || !email) throw new Error('Authentication error');

      const response = await axios.post(
        `${apiBaseUrl}/api/checkout`,
        { priceId, userId, email },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const stripe = await stripePromise;
      await stripe?.redirectToCheckout({ sessionId: response.data.sessionId });
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingState(false);
    }
  };

  const isBasicActive = subscriptionStatus === 'active' && subscriptionType === 'Basic';
  const isPremiumActive = subscriptionStatus === 'active' && subscriptionType === 'Premium';

  return (
    <div
      style={{
        fontFamily: "'Poppins', sans-serif",
        backgroundColor: '#fdfdfd',
        color: '#333',
        overflow: 'hidden',
      }}
    >
      {/* Hero Section */}
      <div
        style={{
          position: 'relative',
          textAlign: 'center',
          padding: '100px 20px',
          background: 'url(cover-image.jpg) no-repeat center center / cover',
          color: 'white',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9))',
            zIndex: 1,
          }}
        />
        <div style={{ position: 'relative', zIndex: 2 }}>
          <Typography variant="h3" style={{ fontWeight: 700, marginBottom: '20px' }}>
            Join the Journey. Transform Your Tango.
          </Typography>
          <Typography variant="h6" style={{ fontWeight: 400, marginBottom: '40px' }}>
            Unlock live classes, detailed breakdowns, and tools to master your tango.
          </Typography>
          <div>
            {isBasicActive && (
              <Typography
                style={{
                  margin: '20px 0',
                  color: '#00796b',
                  fontWeight: 700,
                  fontSize: '16px',
                }}
              >
                Your current subscription is Basic ($10/month)
              </Typography>
            )}
            {isPremiumActive && (
              <Typography
                style={{
                  margin: '20px 0',
                  color: '#e65100',
                  fontWeight: 700,
                  fontSize: '16px',
                }}
              >
                Your current subscription is Premium ($90/month)
              </Typography>
            )}
            <Button
              style={{
                margin: '10px',
                backgroundColor: isBasicActive || isPremiumActive ? '#cccccc' : '#00796b',
                color: isBasicActive || isPremiumActive ? '#666666' : 'white',
                padding: '12px 24px',
                fontSize: '16px',
                fontWeight: 'bold',
                borderRadius: '30px',
                textTransform: 'none',
              }}
              disabled={isBasicActive || isPremiumActive}
              onClick={() => handleSubscribe('pro-monthly', setLoading)} // TEST key for Basic
            >
              {loading ? <CircularProgress size={24} /> : 'Choose Basic ($10/month)'}
            </Button>
            <Button
              style={{
                margin: '10px',
                backgroundColor: isPremiumActive ? '#cccccc' : '#e65100',
                color: isPremiumActive ? '#666666' : 'white',
                padding: '12px 24px',
                fontSize: '16px',
                fontWeight: 'bold',
                borderRadius: '30px',
                textTransform: 'none',
              }}
              disabled={isPremiumActive}
              onClick={() => handleSubscribe('price_1QTuXQIRn82SLOephC5TSR47', setLoadingPremium)} // TEST key for Premium
            >
              {loadingPremium ? <CircularProgress size={24} /> : 'Choose Premium ($90/month)'}
            </Button>
          </div>
        </div>
      </div>

      {/* Subscription Cards */}
      <div style={{ display: 'flex', justifyContent: 'center', padding: '40px 20px' }}>
        <div
          style={{
            width: '350px',
            margin: '20px',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
            backgroundColor: isBasicActive ? '#f5f5f5' : '#fff',
            textAlign: 'center',
            opacity: isBasicActive ? 0.5 : 1,
          }}
        >
          <Typography variant="h5" style={{ marginBottom: '16px', color: '#00796b', fontWeight: 700 }}>
            Basic Plan
          </Typography>
          <Typography style={{ marginBottom: '24px', fontSize: '18px', fontWeight: 400 }}>$10/month</Typography>
          <ul>
            <li>Access all video breakdowns</li>
            <li>Speed controls</li>
            <li>Feedback and position descriptions</li>
            <li>Loop fine-tuned controls by beat</li>
          </ul>
        </div>
        <div
          style={{
            width: '350px',
            margin: '20px',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
            backgroundColor: isPremiumActive ? '#f5f5f5' : '#fff',
            textAlign: 'center',
            opacity: isPremiumActive ? 0.5 : 1,
          }}
        >
          <Typography variant="h5" style={{ marginBottom: '16px', color: '#e65100', fontWeight: 700 }}>
            Premium Plan
          </Typography>
          <Typography style={{ marginBottom: '24px', fontSize: '18px', fontWeight: 400 }}>$90/month</Typography>
          <ul>
            <li>All Basic Plan features</li>
            <li>Live Zoom classes</li>
            <li>Recordings of past classes</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SubscribePage;
