import React, { useRef, useEffect, useState } from 'react';
import { CardContent, FormControl, IconButton, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import DOMPurify from 'dompurify';
import { useDispatch, useSelector } from 'react-redux';
import { BlocItemProps } from './models/Index';
import NotationList from './NotationList';
import VideoPlayer from './VideoPlayer';
import Buttons from './Buttons';
import styles from './BlocItemStyles.module.css';
import Player from '@vimeo/player';
import { setCurrentProsa, setCurrentNotationIndex } from '../Store';
import SpeedControl from './SpeedControl';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase-config';
import Prosa from './Prosa';
import InfoIcon from '@mui/icons-material/Info';
import { height } from '@mui/system';

const BlocItem: React.FC<BlocItemProps> = ({ blocId, index }) => {
  const notationRefs = useRef<Array<HTMLDivElement | null>>([]);
  const dispatch = useDispatch();

  const [bloc, setBloc] = useState<any>(null);
  const [startBeat, setStartBeat] = useState<string | null>(null);
  const [endBeat, setEndBeat] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const currentProsa = useSelector((state: any) => state.bloc.currentProsa);
  const currentNotationIndex = useSelector((state: any) => state.bloc.currentNotationIndex);

  const rawFeedback = bloc?.notationArray[currentNotationIndex]?.rawFeedback || '';
  const [isProsaOpen, setIsProsaOpen] = useState(false);


  const handleStartBeatFromNotation = (time: string) => {
    setStartBeat(time);
  };

  const handleEndBeatFromNotation = (time: string) => {
    setEndBeat(time);
  };


  const toggleProsa = () => {
    setIsProsaOpen((prev) => !prev);
  };

  // Firestore listener for the bloc data
  useEffect(() => {
    const blocDocRef = doc(db, 'blocs', blocId);

    const unsubscribe = onSnapshot(blocDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setBloc(docSnapshot.data());
      } else {
        console.error('Bloc document does not exist.');
      }
    });

    return () => unsubscribe();
  }, [blocId]);

  const handleStartBeatChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    setStartBeat(selectedValue);
    setEndBeat(null);

    if (selectedValue && bloc?.videoUrl) {
      const iframe = document.getElementById(`vimeo-player-${index}`) as HTMLIFrameElement;
      const player = new Player(iframe);
      const timeInSeconds = parseFloat(selectedValue);

      if (!isNaN(timeInSeconds)) {
        player.setCurrentTime(timeInSeconds).catch((error) => {
          console.error('Error setting video time:', error);
        });
      }
    }
  };

  const handleEndBeatChange = (event: SelectChangeEvent<string>) => {
    setEndBeat(event.target.value);
  };

  const filteredEndNotations = bloc?.notationArray.filter(
    (notation: any) => startBeat && parseFloat(notation.time) > parseFloat(startBeat)
  );

  useEffect(() => {
    if (bloc?.videoUrl && typeof window !== 'undefined') {
      const iframe = document.getElementById(`vimeo-player-${index}`) as HTMLIFrameElement;
      const player = new Player(iframe);

      player.on('play', () => setIsPlaying(true));
      player.on('pause', () => setIsPlaying(false));

      player.on('timeupdate', (data: { seconds: number }) => {
        const currentTime = data.seconds;
        const notationIndex = bloc.notationArray.findIndex(
          (notation: any, i: number) =>
            parseFloat(notation.time) <= currentTime &&
            (i === bloc.notationArray.length - 1 || currentTime < parseFloat(bloc.notationArray[i + 1].time))
        );

        if (notationIndex !== -1) {
          const currentNotation = bloc.notationArray[notationIndex];
          if (currentNotation.prosa2) {
            dispatch(setCurrentProsa(currentNotation.prosa2));
          }
          dispatch(setCurrentNotationIndex(notationIndex));
        }
      });
    }
  }, [bloc, index, dispatch]);

  useEffect(() => {
    if (isPlaying && startBeat && endBeat) {
      const startTime = parseFloat(startBeat);
      const endTime = parseFloat(endBeat);

      const iframe = document.getElementById(`vimeo-player-${index}`) as HTMLIFrameElement;
      const player = new Player(iframe);

      const checkLoop = async () => {
        try {
          const currentTime = await player.getCurrentTime();
          if (currentTime >= endTime) {
            await player.setCurrentTime(startTime);
          }
        } catch (error) {
          console.error('Error handling video loop:', error);
        }
      };

      const interval = setInterval(checkLoop, 200);
      return () => clearInterval(interval);
    }
  }, [isPlaying, startBeat, endBeat, index]);

  if (!bloc) {
    return <div>Loading...</div>;
  }

  return (
    <CardContent>
      {bloc.description && (
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(bloc.description) }} />
      )}

      <div className={styles.contentContainer}>
        <div className={styles.videoContainerWrapper}>
   
        <NotationList
          bloc={bloc}
          notationRefs={notationRefs}
          index={index}
          startBeat={startBeat}
          endBeat={endBeat}
          onStartBeatSelect={handleStartBeatFromNotation}
          onEndBeatSelect={handleEndBeatFromNotation}
        />
        
        </div>
      
      </div>

      <div className={styles.contentContainer}>
        <div className={styles.videoContainerWrapper}>
          {bloc.videoUrl && <VideoPlayer videoUrl={bloc.videoUrl} index={index} />}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              marginBottom: '1.5rem',
            }}
          >
              <SpeedControl />  <IconButton
              onClick={toggleProsa}
              style={{
                backgroundColor: '#004d40',
                color: '#ffffff',
                margin: '10px',
                borderRadius: '50%',
                padding: '10px',
              }}
              aria-label="Info"
            >
              <InfoIcon />
            </IconButton>

            {/* Prosa Modal */}
            <Prosa
              currentProsa={DOMPurify.sanitize(currentProsa)}
              rawFeedback={DOMPurify.sanitize(rawFeedback)}
              isOpen={isProsaOpen}
              onClose={toggleProsa}
            />
          </div>
        
        </div>
      </div>

      <Buttons blocId={blocId} />
      <div  style={{height: '0px',
              }}></div>
    </CardContent>
  );
};

export default BlocItem;
