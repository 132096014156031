import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, setCurrentNotationIndex } from '../Store';
import { Bloc } from './models/Index';
import { IconButton, Modal, Typography, TextField, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase-config';
import { useAuth } from '../context/AuthContext';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';


interface NotationListProps {
  bloc: Bloc;
  notationRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;
  index: number;
  startBeat: string | null;
  endBeat: string | null;
  onStartBeatSelect: (time: string) => void; // Add this
  onEndBeatSelect: (time: string) => void; // Add this
}

const NotationList: React.FC<NotationListProps> = ({
  bloc,
  notationRefs,
  index,
  startBeat,
  endBeat,
  onStartBeatSelect,
  onEndBeatSelect
}) => {
  const dispatch = useDispatch();
  const { isAdmin } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [selectedNotationIndex, setSelectedNotationIndex] = useState<number | null>(null);
  const [feedback, setFeedback] = useState('');

  const currentNotationIndex = useSelector(
    (state: RootState) => state.bloc.currentNotationIndex
  );
  const mergingPoints = useSelector((state: RootState) => state.bloc.mergingPoints);

  const isInSelectedRange = (notationTime: string): boolean => {
    const timeValue = parseFloat(notationTime);
    return (
      startBeat !== null &&
      endBeat !== null &&
      timeValue >= parseFloat(startBeat) &&
      timeValue <= parseFloat(endBeat)
    );
  };

  const handleNotationClick = (time: string, notationIndex: number) => {
    const timeInSeconds = parseFloat(time);
    const iframe = document.getElementById(`vimeo-player-${index}`) as HTMLIFrameElement;

    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        { method: 'setCurrentTime', value: timeInSeconds },
        '*'
      );
    }

    dispatch(setCurrentNotationIndex(notationIndex));
  };

  const handleEditClick = async (notationIndex: number) => {
    setSelectedNotationIndex(notationIndex);
    setFeedback('');

    try {
      const blocDocRef = doc(db, 'blocs', bloc.id);
      const blocSnapshot = await getDoc(blocDocRef);

      if (blocSnapshot.exists()) {
        const updatedBloc = blocSnapshot.data() as Bloc;
        const selectedNotation = updatedBloc.notationArray[notationIndex];

        if (selectedNotation.rawFeedback) {
          setFeedback(selectedNotation.rawFeedback);
        }
      }
    } catch (error) {
      console.error('Error fetching feedback:', error);
    } finally {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedNotationIndex(null);
  };

  const handleSaveFeedback = async () => {
    if (selectedNotationIndex !== null) {
      try {
        const updatedNotationArray = bloc.notationArray.map((notation, index) =>
          index === selectedNotationIndex
            ? { ...notation, rawFeedback: feedback }
            : notation
        );

        const blocDocRef = doc(db, 'blocs', bloc.id);
        await updateDoc(blocDocRef, {
          notationArray: updatedNotationArray,
        });

        console.log('Feedback saved successfully:', feedback);
      } catch (error) {
        console.error('Error saving feedback:', error);
      } finally {
        setOpenModal(false);
      }
    }
  };

  useEffect(() => {
    if (
      typeof currentNotationIndex === 'number' &&
      notationRefs.current[currentNotationIndex]
    ) {
      notationRefs.current[currentNotationIndex]?.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
      });
    }
  }, [currentNotationIndex, notationRefs]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '15px',
          borderRadius: '12px',
          backgroundColor: '#004d40',
          color: '#ffffff',
          overflowX: 'auto',
          scrollbarWidth: 'thin',
          scrollbarColor: '#80cbc4 #003d33',
          marginBottom: '15px',
          gap: '12px',
        }}
      >
        {bloc.notationArray.map((notation, notationIndex) => {
          const isMergingPoint = mergingPoints.includes(notationIndex);
          const isActive = currentNotationIndex === notationIndex;
          const isHighlighted = isInSelectedRange(notation.time);

         

          return (
            <div
              key={notationIndex}
              ref={(el) => (notationRefs.current[notationIndex] = el)}
              style={{
                marginRight: '12px',
                padding: '12px',
                backgroundColor: isActive ? '#80cbc4' : '#00796b',
                borderRadius: '12px',
                color: isActive ? '#003d33' : '#ffffff',
                fontWeight: 'bold',
                textAlign: 'center',
                width: '80px',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '16px',
                transition: 'box-shadow 0.3s ease, background-color 0.3s ease',
                border: isHighlighted
                  ? '2px solid #ff7043'
                  : isActive
                  ? '2px solid #004d40'
                  : '1px solid #ccc',
              }}
              onClick={() => handleNotationClick(notation.time, notationIndex)}
            >
              <div>
                <strong>{notation.beat}.{notation.innerBeat}</strong>
              </div>
              <div>{notation.sy}</div>
              <div>
                {notation.ln}
                {notation.la} {notation.lf}
              </div>
              <div>
                {notation.fn}
                {notation.fa} {notation.ff}
              </div>
              <div style={{ display: 'flex', gap: '8px', marginTop: '8px' }}>
  {/* Set Start Beat */}
  <IconButton
  size="small"
  onClick={() => onStartBeatSelect(notation.time)} // Use onStartBeatSelect prop
  style={{ color: '#ffb74d' }}
  aria-label="Set Start Beat"
>
  <PlayArrowIcon fontSize="small" />
</IconButton>

<IconButton
  size="small"
  onClick={() => onEndBeatSelect(notation.time)} // Use onEndBeatSelect prop
  style={{ color: '#e57373' }}
  aria-label="Set End Beat"
>
  <PlayArrowIcon fontSize="small" />
</IconButton>

  {/* Edit Feedback (Admin only) */}
  {isAdmin && (
    <IconButton
      size="small"
      onClick={() => handleEditClick(notationIndex)}
      aria-label="Edit Feedback"
      style={{ color: '#80cbc4' }}
    >
      <EditIcon fontSize="small" />
    </IconButton>
  )}
</div>
            </div>
          );
        })}
      </div>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="feedback-modal-title"
        aria-describedby="feedback-modal-description"
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
            borderRadius: '8px',
            padding: '20px',
            width: '400px',
          }}
        >
          <Typography id="feedback-modal-title" variant="h6" style={{ marginBottom: '16px' }}>
            Add Feedback
          </Typography>
          {selectedNotationIndex !== null && (
            <Typography variant="body2" style={{ marginBottom: '16px' }}>
              <strong>{bloc.notationArray[selectedNotationIndex].sy}</strong> -{' '}
              {bloc.notationArray[selectedNotationIndex].ln} {bloc.notationArray[selectedNotationIndex].la} |{' '}
              {bloc.notationArray[selectedNotationIndex].fn} {bloc.notationArray[selectedNotationIndex].fa}
            </Typography>
          )}
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            placeholder="Write your feedback here..."
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            style={{ marginBottom: '16px' }}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" onClick={handleSaveFeedback} style={{ marginRight: '8px' }}>
              Save
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NotationList;
