import React, { createContext, useContext, useEffect, useState, ReactNode, useMemo } from 'react';
import { onAuthStateChanged, User, signOut as firebaseSignOut } from 'firebase/auth';
import { auth, db } from '../firebase-config';
import { doc, getDoc } from 'firebase/firestore';

interface ExtendedUser extends User {
  subscriptionStatus?: string;
  stripeSubscriptionId?: string;
  token?: string;
}

interface AuthContextType {
  user: ExtendedUser | null;
  isAdmin: boolean;
  isSubscribed: boolean;
  selectedUser: ExtendedUser | null;
  setSelectedUser: (user: ExtendedUser | null) => void;
  signOut: () => Promise<void>;
  token: string | null;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  isAdmin: false,
  isSubscribed: false,
  selectedUser: null,
  setSelectedUser: () => {},
  signOut: async () => {},
  token: null,
});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<ExtendedUser | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<ExtendedUser | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      try {
        if (user) {
          const fetchedToken = await user.getIdToken();
          setToken(fetchedToken);

          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();

            const extendedUser: ExtendedUser = {
              ...user,
              token: fetchedToken,
              subscriptionStatus: userData?.subscriptionStatus,
              stripeSubscriptionId: userData?.stripeSubscriptionId,
            };

            setCurrentUser(extendedUser);
            setIsSubscribed(userData?.subscriptionStatus === 'active');
            setIsAdmin(user.uid === '8dy0t60qvKZlTGfe8LkNYpFXkRq2');
            setSelectedUser(extendedUser);
          } else {
            console.error('User document does not exist in Firestore.');
            resetAuthState();
          }
        } else {
          resetAuthState();
        }
      } catch (error) {
        console.error('Error during auth state resolution:', error);
        resetAuthState();
      } finally {
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  const resetAuthState = () => {
    setCurrentUser(null);
    setIsAdmin(false);
    setIsSubscribed(false);
    setSelectedUser(null);
    setToken(null);
  };

  const signOut = async () => {
    await firebaseSignOut(auth);
    resetAuthState();
  };

  const contextValue = useMemo(
    () => ({
      user: currentUser,
      isAdmin,
      isSubscribed,
      selectedUser,
      setSelectedUser,
      signOut,
      token,
    }),
    [currentUser, isAdmin, isSubscribed, selectedUser, token]
  );

  if (loading) {
    return <div>Loading...</div>; // Replace with a proper loading spinner or placeholder
  }

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
