import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { db } from '../firebase-config';
import { collection, getDocs } from 'firebase/firestore';
import styles from './NotationMatchesStyles.module.css';

interface Notation {
  sy: string;
  ln: string;
  la: string;
  fn: string;
  fa: string;
}

interface Match {
  id: string;
  title?: string;
}

const NotationMatches: React.FC = () => {
  const location = useLocation();
  const notation = location.state as Notation; // Access notation details passed via navigate
  const [matches, setMatches] = useState<Match[]>([]);
  const [loading, setLoading] = useState(true);

  console.log(notation.sy)

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'blocs'));
        const results: Match[] = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const notationArray = data.notationArray || [];

          // Check if any element in the notationArray matches the selected notation
          const isMatch = notationArray.some(


            (item: Notation) =>
              item.sy == notation.sy &&
              item.ln == notation.ln &&
              item.la == notation.la &&
              item.fn == notation.fn &&
              item.fa == notation.fa
          );

          if (isMatch) {
            results.push({
              id: doc.id,
              title: data.title || 'Untitled Bloc',
            });
          }
        });

        setMatches(results);
      } catch (error) {
        console.error('Error fetching matches:', error);
      } finally {
        setLoading(false);
      }
    };

    if (notation) {
      fetchMatches();
    }
  }, [notation]);

  if (loading) {
    return <div className={styles.loading}>Loading matches...</div>;
  }

  return (
    <div className={styles.matchesContainer}>
      <h2>Matches for Selected Notation</h2>
      {matches.length > 0 ? (
        matches.map((match) => (
          <div key={match.id} className={styles.matchCard}>
            <h3>{match.title}</h3>
          </div>
        ))
      ) : (
        <p>No matches found.</p>
      )}
    </div>
  );
};

export default NotationMatches;
