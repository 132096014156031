import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import DateTimeDisplay from './DateTimeDisplay';
import { Lesson } from '../models/Lesson';

interface LessonCardProps {
  lesson: Lesson;
  index: number;
  onClick: () => void;
}

const LessonCard: React.FC<LessonCardProps> = ({ lesson, onClick }) => {
  const backgroundImage = lesson.thumbnailUrl
    ? `url(${lesson.thumbnailUrl})`
    : 'url(default-image-url.png)';

  // Use the numeric contentPosition or default to 70% (bottom)
  const contentPosition = lesson.contentPosition || 70;

  // Opacity can be dynamically controlled
  const contentOpacity = lesson.contentOpacity || 0.8;

  return (
    <Card
      style={{
        width: '300px',
        height: '533px',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '30px',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
        backgroundImage: backgroundImage,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardContent
        style={{
          zIndex: 2,
          position: 'absolute',
          top: `${contentPosition}%`, // Dynamic positioning based on contentPosition
          transform: 'translateY(-50%)',
          width: '100%',
          height: '30%',
          background: `rgba(0, 0, 0, ${contentOpacity})`, // Dynamic opacity
          color: '#e0f2f1',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px',
          boxSizing: 'border-box',
        }}
      >
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Typography
            style={{
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 700,
              fontSize: '18px',
              color: '#ffffff',
            }}
          >
            {lesson.topic}
          </Typography>
          <DateTimeDisplay date={lesson.date} time={lesson.time} />
          <button
            onClick={onClick}
            style={{
              textDecoration: 'none',
              fontFamily: "'Roboto', sans-serif",
              fontWeight: 500,
              fontSize: '14px',
              backgroundColor: '#ff7043',
              color: '#ffffff',
              padding: '8px 16px',
              borderRadius: '20px',
              cursor: 'pointer',
              border: 'none',
            }}
          >
            Open Zoom Here
          </button>
        </div>
      </CardContent>
    </Card>
  );
};

export default LessonCard;