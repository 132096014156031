import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Box, Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme, useMediaQuery } from '@mui/material';
import NavLinks from './NavBarLinks';
import HamburgerMenu from './HamburgerMenu';
import { Link as RouterLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';

const NavBar: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  return (
    <AppBar
      position="static"
      sx={{
        background: 'linear-gradient(to bottom left, #008080, #004D4D)',
        padding: isMobile ? '0 16px' : '0 32px',
      }}
    >
      <Toolbar disableGutters>
            <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
        <Typography
          component={RouterLink}
          to="/"
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <HomeIcon style={{ fontSize: '28px', color: 'white' }} />
        </Typography>

        <Typography
          variant="h5"
          component={RouterLink}
          to="/zoomclasses"
          style={{
            color: 'white',
            textDecoration: 'none',
            fontWeight: 'bold',
            letterSpacing: '1.5px',
            fontFamily: 'Helvetica, Arial, sans-serif',
          }}
        >
          ZOOM
        </Typography>
      </div>
        {isMobile ? (
          <>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={toggleDrawer(true)}
              sx={{ ml: 'auto' }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
              <HamburgerMenu onClose={toggleDrawer(false)} />
            </Drawer>
          </>
        ) : (
          <NavLinks />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;