import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, doc } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import LessonCard from './LessonCard';
import { Lesson } from '../models/Lesson';

const ZoomClassesDashboard: React.FC = () => {
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [loading, setLoading] = useState(true);
  const [userSubscription, setUserSubscription] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      navigate('/login');
      return;
    }

    const userDocRef = doc(db, 'users', currentUser.uid);
    const unsubscribeUser = onSnapshot(userDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setUserSubscription(docSnapshot.data()?.subscriptionType || null);
      } else {
        console.error('User document does not exist!');
        setUserSubscription(null);
      }
    });

    return () => unsubscribeUser();
  }, [navigate]);

  useEffect(() => {
    const lessonsCollection = collection(db, 'lessons');
    const unsubscribeLessons = onSnapshot(lessonsCollection, (snapshot) => {
      const lessonsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Lesson[];

      setLessons(lessonsData);
      setLoading(false);
    });

    return () => unsubscribeLessons();
  }, []);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '16px' }}>
        <Typography variant="h6" style={{ color: '#004d40' }}>
          Loading lessons...
        </Typography>
      </div>
    );
  }

  return (
    <div style={{ padding: '16px' }}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        style={{ color: '#004d40', marginBottom: '16px' }}
      >
        Upcoming Zoom Classes
      </Typography>

      <Grid
        container
        spacing={2}
        style={{
          margin: '0 auto',
          maxWidth: '100%',
        }}
      >
        {lessons.map((lesson, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={lesson.id}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <LessonCard
              lesson={lesson}
              index={index}
              onClick={() =>
                userSubscription === 'Premium'
                  ? window.open(lesson.zoomLink, '_blank')
                  : navigate('/subscribe')
              }
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ZoomClassesDashboard;
